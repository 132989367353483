html {
    background: #bbb;
}

.player {
    display: flex;
    flex-direction: column;
    background: #999;
    padding: 10px;
    overflow: hidden;
}

.artist-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.music-player--details img{
    float: left;
    width: 150px;
    margin-right: 10px;
}

.music-player--details h3 {
    margin: 0;
    line-height: 25px;
    text-align: center;
    padding-bottom: 5px;
    font-weight: normal;
    border-bottom: 1px solid #555;
}

.music-player--details h4 {
    font-weight: normal;
    color: #444;
    margin: 0;
    text-align: center;
}

.music-player--controls {
    margin: 10px 0;
    border-radius: 10px;
    padding: 10px;
    display: inline-block;
    background: #888;
}

.wrapperAudio {
    display: flex;
    align-items: center;
    align-self: center;
}


.player button {
    border: none;
    cursor: pointer;
}

.music-player--controls button:hover {
    background: #3b9f6e;
}

.play-btn,
.skip-btn {
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 0;
    box-sizing: border-box;
    margin: 0 10px;
    border-radius: 50%;
    background: #31c27c;
}

.skip-btn:after {
    position: absolute;
    top: 13px;
    left: 18px;
    content: "";
    display : inline-block;
    height : 0;
    width : 0;
    border-right : 8px solid transparent;
    border-bottom : 14px solid #fff;
    border-left : 8px solid transparent;
    transform: rotate(90deg);    
}

.skip-btn:before {
    position: absolute;
    top: 13px;
    left: 8px;
    content: "";
    display : inline-block;
    height : 0;
    width : 0;
    border-right : 8px solid transparent;
    border-bottom : 14px solid #fff;
    border-left : 8px solid transparent;
    transform: rotate(90deg);
}

.skip-btn.prev {
    transform: rotate(180deg);
}

.skip-btn.prev.inactive {
    opacity: 0.5;
    
}

.play-btn.play:before {
    position: absolute;
    top: 10px;
    left: 13px;
    content: "";
    display : inline-block;
    height : 0;
    width : 0;
    border-right : 9px solid transparent;
    border-bottom : 20px solid #fff;
    border-left : 9px solid transparent;
    transform: rotate(90deg);
}

.play-btn.pause:before {
    position: absolute;
    top: 9px;
    left: 12px;
    content: "";
    display : inline-block;
    height : 20px;
    width : 5px;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
}


.player audio {
    margin: 0 10px;
}

.soundVolume{
    display: flex;
    align-items: baseline;
    margin: 23px auto;
}

.playlist ul {
    margin: 0;
    padding: 0;
}

0 {
    padding: 5px 10px;
    list-style-type: none;
    color: #333;
    cursor: pointer;
    padding-left: 25px;
}

.playlist li:nth-child(odd){
    background: #aaa;
}

.playlist li:hover {
    color: #fff;
    background: #333;
}

.playlist li.played {
    color: #b9b9b9;
    text-decoration: line-through;
    background: #878787;
}

.playlist li.active{
    padding-left: 5px;
    background: #31c27c;
}
.playlist li.active:before {
    content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 18.25 14.25' version='1.1' viewBox='0 0 18.25 14.25' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23111111;%7D%0A%3C/style%3E%3Cpath class='st0' d='M9.47,0.66L4.94,4.58H0.66v5.04h4.22l4.59,3.97V0.66z'/%3E%3Cpath class='st0' d='m9.47 14.25c-0.16 0-0.31-0.06-0.43-0.16l-4.41-3.81h-3.97c-0.36-0.01-0.66-0.3-0.66-0.67v-5.03c0-0.36 0.3-0.66 0.66-0.66h4.04l4.34-3.75c0.2-0.17 0.47-0.21 0.71-0.1s0.39 0.34 0.39 0.6v12.93c0 0.26-0.15 0.49-0.39 0.6-0.09 0.03-0.19 0.05-0.28 0.05zm-8.15-5.3h3.56c0.16 0 0.31 0.06 0.43 0.16l3.5 3.03v-10.04l-3.43 2.97c-0.12 0.11-0.28 0.17-0.44 0.17h-3.62v3.71z'/%3E%3Cpath class='st0' d='m15.33 14.21c-0.14 0-0.29-0.05-0.41-0.15-0.28-0.23-0.33-0.64-0.1-0.93 2.79-3.48 2.79-8.52 0-12-0.23-0.28-0.18-0.7 0.1-0.93s0.7-0.18 0.93 0.1c1.55 1.93 2.4 4.35 2.4 6.83s-0.85 4.9-2.4 6.83c-0.13 0.16-0.32 0.25-0.52 0.25zm-1.71-1.72c-0.14 0-0.27-0.04-0.39-0.13-0.29-0.22-0.36-0.63-0.14-0.92 1.9-2.58 1.9-6.04 0-8.61-0.22-0.29-0.15-0.71 0.14-0.92s0.71-0.15 0.92 0.14c1.09 1.48 1.67 3.24 1.67 5.09s-0.58 3.6-1.67 5.09c-0.13 0.17-0.33 0.26-0.53 0.26zm-1.88-1.87c-0.12 0-0.25-0.04-0.36-0.11-0.3-0.2-0.39-0.61-0.19-0.91 0.98-1.5 0.98-3.43 0-4.92-0.2-0.3-0.12-0.71 0.19-0.91 0.3-0.2 0.71-0.12 0.91 0.19 1.27 1.94 1.27 4.44 0 6.37-0.12 0.18-0.33 0.29-0.55 0.29z'/%3E%3C/svg%3E%0A");
    width: 15px;
    height: 10px;
    display: inline-block;
    top: 3px;
    margin: 4px 5px 4px 0;
}

.soundVolume span {
    width: 40px;
    border-left: 1px solid #42e797;
    height: 10px;
    cursor: pointer;
    background: #31c27c;
}

.soundVolume span:hover,
.soundVolume span.active {
    background: #fff;
}

.soundVolume span:nth-child(1){height: 5px;}
.soundVolume span:nth-child(2){height: 10px;}
.soundVolume span:nth-child(3){height: 15px;}
.soundVolume span:nth-child(4){height: 20px;}
.soundVolume span:nth-child(5){height: 25px;}
.soundVolume span:nth-child(6){height: 30px;}
.soundVolume span:nth-child(7){height: 35px;}
.soundVolume span:nth-child(8){height: 40px;}
.soundVolume span:nth-child(9){height: 45px;}
.soundVolume span:nth-child(10){height: 50px;}

video {
    text-align: center;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #000;
}

.bandana {
    /* display: block; */
    display: none;
    background: #999;
    padding: 10px 0;
    border-top: 1px solid #000;
}

nav {
    display: flex;
}

.reinit {
    border-radius: 50%;
    background: #31c27c;
    display: inline-block;
    height: 45px;
    width: 45px;
    margin: 10px;
    position: relative;
    border: 1px solid rgb(141, 141, 141);
}

.reinit:before {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 5px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-top: none;
    transform: rotate(52deg);
}

.reinit:after {
    position: absolute;
    content: "";
    display: block;
    top: 1px;
    left: 15px;
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-bottom: 14px solid #fff;
    border-left: 8px solid transparent;
    transform: rotate(-26deg);
}

.reinit:hover {
    background: #fff;
    cursor: pointer;
}

.reinit:hover:before {
    border-color:#31c27c;
}

.reinit:hover:after {
    border-bottom: 14px solid #31c27c;
}

.App .button {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    background: #31c27c;
    color: #fff;
    cursor: pointer;
    border-top: 5px solid #31c27c;
}

.App .button:hover,
.App .button.active {
    background: #fff;
    color: #000;
}

.ear {
    background: url(./src/oreille.svg) no-repeat 0 0;
    float: left;
    margin-top: 40px;
    margin-left: 30px;
    width: 35px;
    height: 55px;
    cursor: pointer;
    position: absolute;
}

.earOff {
    background: url(./src/oreille.svg) no-repeat 0 0;
}

.earOff:before {
    content: " ";
    position: absolute;
    border-left: 4px solid #000;
    height: 55px;
    top: 0;
    left: 15px;
    transform: rotate(-15deg);
}

.transcript {
    padding: 0 10px;
    margin: 10px;
    border: 1px solid #555;
    color: #555;
}

/*
  @media screen and (max-width: 1025px){}
  */